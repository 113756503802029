import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/database',
    name: 'Database',
    component: () => import('../views/database.vue')
  },
  {
    path: '/expert',
    name: 'Expert',
    component: () => import('../views/expertIntroduction.vue')
  },
  {
    path: '/project/:type',
    name: 'Project',
    component: () => import('../views/projectSchedule.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news.vue'),
  },
  {
    path: '/news/news1',
    name: 'News1',
    component: () => import('../views/components/news1')
  },
  {
    path: '/news/news2',
    name: 'News2',
    component: () => import('../views/components/news2')
  },
  {
    path: '/news/news3',
    name: 'News3',
    component: () => import('../views/components/news3')
  },
  {
    path: '/news/news4',
    name: 'News4',
    component: () => import('../views/components/news4')
  },
  {
    path: '/news/news5',
    name: 'News5',
    component: () => import('../views/components/news5')
  },
  {
    path: '/news/news6',
    name: 'News6',
    component: () => import('../views/components/news6')
  },
  {
    path: '/news/news7',
    name: 'News7',
    component: () => import('../views/components/news7')
  },
  {
    path: '/news/news8',
    name: 'News8',
    component: () => import('../views/components/news8')
  },
  {
    path: '/news/news9',
    name: 'News9',
    component: () => import('../views/components/news9')
  },
  {
    path: '/joinUs/:type',
    name: 'JoinUs',
    component: () => import('../views/joinUs.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
