<template>
  <div class="header-wrap">
    <img
      v-if="curTab == 'Home'"
      src="@/assets/images/background.png"
      width="100%"
    />
    <img v-else src="@/assets/images/background1.png" width="100%" />
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <div>{{ $t('licence.guangzhou') }}</div>
      </template>
      <div
        class="circle-b"
        :class="[curTab == 'Home' ? 'circle-1' : 'circle-1-1']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.huizhou') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-2' : 'circle-2-2']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.zhanjiang') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-3' : 'circle-3-3']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.foshan') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-4' : 'circle-4-4']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.guiyang') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-5' : 'circle-5-5']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.shaoguan') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-6' : 'circle-6-6']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.nanning') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-7' : 'circle-7-7']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.guizhou') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-8' : 'circle-8-8']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.kunming') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-9' : 'circle-9-9']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.chengdu') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-10' : 'circle-10-10']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.chongqing') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-11' : 'circle-11-11']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.kasshi') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-12' : 'circle-12-12']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.ganzhou') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-13' : 'circle-13-13']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.hefei') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-14' : 'circle-14-14']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.jinan') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-15' : 'circle-15-15']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.nanjing') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-16' : 'circle-16-16']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.hangzhou') }}</span>
      </template>
      <div
        class="circle-b"
        :class="[curTab == 'Home' ? 'circle-17' : 'circle-17-17']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.dalian') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-18' : 'circle-18-18']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.beijing') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-19' : 'circle-19-19']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.xining') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-20' : 'circle-20-20']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.wuhan') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-21' : 'circle-21-21']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.shanghai') }}</span>
      </template>
      <div
        class="circle-b"
        :class="[curTab == 'Home' ? 'circle-22' : 'circle-22-22']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.shenzhen') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-23' : 'circle-23-23']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.zhuhai') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-24' : 'circle-24-24']"
      ></div>
    </a-popover>
    <a-popover class="z99" overlayClassName="autoTip">
      <template slot="content">
        <span>{{ $t('licence.maoming') }}</span>
      </template>
      <div
        class="circle"
        :class="[curTab == 'Home' ? 'circle-25' : 'circle-25-25']"
      ></div>
    </a-popover>
    <div
      class="top-wrap disflex justify-sb"
      :class="{ backImg: curTab != 'Home' }"
    >
      <div class="title disflex">
        <div class="crds">CRDS</div>
        <div class="title-right">
          <div>中国肾脏病大数据协作网平台</div>
          <div>China Renal Data System</div>
        </div>
      </div>
      <div class="disflex">
        <a-tabs class="tabs" v-model="curTab" @change="callback">
          <a-tab-pane key="Home" :tab="$t('licence.home')"> </a-tab-pane>
          <a-tab-pane key="Database" :tab="$t('licence.database')">
          </a-tab-pane>
          <a-tab-pane key="Expert" :tab="$t('licence.expert')"> </a-tab-pane>
          <a-tab-pane key="Project" :tab="$t('licence.project')"> </a-tab-pane>
          <a-tab-pane key="News" :tab="$t('licence.news')"> </a-tab-pane>
          <a-tab-pane key="JoinUs" :tab="$t('licence.join')" force-render>
          </a-tab-pane>
        </a-tabs>
        <div
          v-if="$i18n.locale == 'zh'"
          @click="changeLang('en')"
          class="lang-btn mr-20 ml-30 cp"
        >
          中文简
        </div>
        <div v-else @click="changeLang('zh')" class="lang-btn mr-20 ml-30 cp">
          English
        </div>
      </div>
    </div>
    <div class="big-data tl">
      <div
        :class="
          $i18n.locale == 'zh'
            ? curTab == 'Home'
              ? 'zhSFh'
              : 'zhSF'
            : curTab == 'Home'
            ? 'enSFh'
            : 'enSF'
        "
        class="fb mb-20"
      >
        {{ $t('licence.title') }}
      </div>
      <div
        :class="
          $i18n.locale == 'zh'
            ? curTab == 'Home'
              ? 'zhBFh'
              : 'zhBF'
            : curTab == 'Home'
            ? 'enBFh'
            : 'enBF'
        "
        class="fb"
      >
        {{ $t('licence.futures') }}
      </div>
      <div
        :class="
          $i18n.locale == 'zh'
            ? curTab == 'Home'
              ? 'zhBFh'
              : 'zhBF'
            : curTab == 'Home'
            ? 'enBFh'
            : 'enBF'
        "
        class="disflex"
      >
        <div class="fb mr-20">{{ $t('licence.commonInterconnected') }}</div>
        <div class="fb">{{ $t('licence.cooperationSharing') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curTab: 'Home',
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.curTab = route.name;
      },
      immediate: true,
    },
  },
  methods: {
    changeLang(key) {
      this.$i18n.locale = key;
      if (key == 'zh') {
        document.title = '中国肾脏病大数据协作网平台';
      } else {
        document.title = 'China Renal Data System';
      }
    },
    callback(key) {
      this.curTab = key;
      if (key == 'Project') {
        this.$router.push({ name: key, params: { type: 'database' } });
      } else if (key == 'JoinUs') {
        this.$router.push({ name: key, params: { type: 'joinus' } });
      } else {
        this.$router.push({ name: key });
      }
    },
  },
};
</script>
<style lang="scss">
.autoTip {
  .ant-popover-inner {
    border-radius: 50%;
  }
  .ant-popover-inner-content {
    padding: 10px;
  }
}
</style>
<style lang="scss" scoped>
/deep/.ant-tabs-nav-container {
  font-size: 18px !important;
}
/deep/.ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 22px 12px 12px;
}
/deep/.ant-tabs-bar {
  border-bottom: none;
}
/deep/.ant-tabs-nav .ant-tabs-tab-active {
  color: #1e328e;
}
/deep/.ant-tabs-ink-bar {
  background-color: #1e328e;
}
.header-wrap {
  position: relative;
}
.big-data {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 15px 20px;
  position: absolute;
  top: 24%;
  left: 70px;
  color: #fff;
  .zhSF {
    font-size: 36px;
  }
  .zhSFh {
    font-size: 50px;
  }
  .zhBF {
    font-size: 50px;
  }
  .zhBFh {
    font-size: 80px;
  }
  .enBF {
    font-size: 46px;
    letter-spacing: -1px;
  }
  .enBFh {
    font-size: 56px;
    letter-spacing: -2px;
  }
  .enSF {
    font-size: 32px;
    letter-spacing: -1px;
  }
  .enSFh {
    font-size: 40px;
    letter-spacing: -2px;
  }
}
.backImg {
  background-image: url('../assets/images/topBack.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.circle {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  cursor: pointer;
  // background-color: pink;
}
.circle-b {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  cursor: pointer;
  // background-color: pink;
}
.z99 {
  z-index: 99;
}
.circle-1 {
  top: 63.4%;
  right: 26.8%;
}
.circle-1-1 {
  top: 78.7%;
  right: 26.9%;
}
.circle-2 {
  top: 63.5%;
  right: 25.4%;
}
.circle-2-2 {
  top: 79.2%;
  right: 25.4%;
}
.circle-3 {
  top: 67%;
  right: 29.3%;
}
.circle-3-3 {
  top: 86.8%;
  right: 29.3%;
}
.circle-4 {
  top: 63.8%;
  right: 27.6%;
}
.circle-4-4 {
  top: 80.1%;
  right: 27.6%;
}
.circle-5 {
  top: 60.5%;
  right: 29.6%;
}
.circle-5-5 {
  top: 72.6%;
  right: 29.6%;
}
.circle-6 {
  top: 61.4%;
  right: 26.7%;
}
.circle-6-6 {
  top: 75%;
  right: 26.7%;
}
.circle-7 {
  top: 64%;
  right: 31.1%;
}
.circle-7-7 {
  top: 80.3%;
  right: 31.1%;
}
.circle-8 {
  top: 58%;
  right: 32.1%;
}
.circle-8-8 {
  top: 67.3%;
  right: 32.1%;
}
.circle-9 {
  top: 60.8%;
  right: 35.1%;
}
.circle-9-9 {
  top: 73.3%;
  right: 35.1%;
}
.circle-10 {
  top: 52%;
  right: 34.1%;
}
.circle-10-10 {
  top: 53.9%;
  right: 34.1%;
}
.circle-11 {
  top: 54.1%;
  right: 32.1%;
}
.circle-11-11 {
  top: 58.7%;
  right: 32.1%;
}
.circle-12 {
  top: 37.3%;
  right: 55.8%;
}
.circle-12-12 {
  top: 22.7%;
  right: 55.8%;
}
.circle-13 {
  top: 58.2%;
  right: 25.7%;
}
.circle-13-13 {
  top: 67.8%;
  right: 25.7%;
}
.circle-14 {
  top: 50.4%;
  right: 24.1%;
}
.circle-14-14 {
  top: 50.9%;
  right: 24.1%;
}
.circle-15 {
  top: 41.4%;
  right: 23.5%;
}
.circle-15-15 {
  top: 31.5%;
  right: 23.5%;
}
.circle-16 {
  top: 49.7%;
  right: 22.6%;
}
.circle-16-16 {
  top: 49.2%;
  right: 22.6%;
}
.circle-17 {
  top: 52.3%;
  right: 21.5%;
}
.circle-17-17 {
  top: 55.2%;
  right: 21.5%;
}
.circle-18 {
  top: 38.2%;
  right: 20.5%;
}
.circle-18-18 {
  top: 24.6%;
  right: 20.5%;
}
.circle-19 {
  top: 36%;
  right: 24.4%;
}
.circle-19-19 {
  top: 19.6%;
  right: 24.4%;
}
.circle-20 {
  top: 41.7%;
  right: 36.3%;
}
.circle-20-20 {
  top: 32.2%;
  right: 36.2%;
}
.circle-21 {
  top: 52.2%;
  right: 25.8%;
}
.circle-21-21 {
  top: 54.8%;
  right: 25.8%;
}
.circle-22 {
  top: 50.8%;
  right: 20.6%;
}
.circle-22-22 {
  top: 51.4%;
  right: 20.6%;
}
.circle-23 {
  top: 64.8%;
  right: 26.4%;
}
.circle-23-23 {
  top: 82%;
  right: 26.3%;
}
.circle-24 {
  top: 65.4%;
  right: 27%;
}
.circle-24-24 {
  top: 83.4%;
  right: 27%;
}
.circle-25 {
  top: 66%;
  right: 28.7%;
}
.circle-25-25 {
  top: 84.6%;
  right: 28.7%;
}
.top-wrap {
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  .title {
    color: #fff;
    padding-left: 20px;
    .crds {
      font-size: 20px;
      font-weight: bold;
    }
    .title-right {
      padding-left: 10px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
  }
  .tabs {
    color: #fff;
  }
  .lang-btn {
    font-size: 18px;
    color: #fff;
    border: 1px solid #fff;
    padding: 0 5px;
  }
}
</style>
