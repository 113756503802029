<template>
  <div class="footer disflex justify-sb">
    <div>
      <div>
        <img
          src="@/assets/images/CRDS-logo.png"
          height="160"
        ><img
          class="mr-20"
          src="@/assets/images/logo-ppt.png"
          height="80"
        ><img
          src="@/assets/images/szb-logo.png"
          height="80"
        >
      </div>
      <div class="pl-50 f16">@2022 copyright {{$t('licence.title')}} {{$t('licence.allRightsReserved')}}</div>
    </div>
    <div class="link">
      <div>
        <div class="f20 mb-20">{{$t('licence.links')}}</div>
        <div class="f16 mb-10">
          <a
            style="color: black"
            target="_blank"
            href="http://www.ncrckd.cn/"
          >{{$t('licence.footer')}}</a>
        </div>
        <div class="f16">
          <a
            style="color: black"
            target="_blank"
            href="http://www.nfyy.com/"
          >{{$t('licence.nanFangHospital')}}</a>
        </div>
      </div>
      <div class="ml-50">
        <div class="f20 mb-20">{{$t('licence.contact')}}</div>
        <div class="f16 mb-10">ncrckd@163.com</div>
        <div class="f16">020-62786329</div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
};
</script>

<style scoped lang="scss">
.footer {
  padding: 0px 120px 50px 0;
  .link {
    display: flex;
    height: 184px;
    align-items: flex-end;
  }
}
</style>
